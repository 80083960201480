import {
  CHANCE_CURRENT_PATH,
  CHANGE_EXTRA_QUESTION_VALUE,
  CHANGE_QUESTION_TO_COMPLETED,
  CHANGE_QUESTION_VALUE,
  CHANGE_SELECTED_QUESTION,
  CLEAN_PROM_DATA,
  CREATE_PATIENT_DISEASE,
  CREATE_PATIENT_DISEASE_ERROR,
  FETCH_ASSESSMENT_SCORE,
  FETCH_PROM_PATIENT_DISEASE_INSTITUTION,
  FETCH_PROM_SECTION,
  FETCH_PROM_SUCCESS,
  SET_TRANSITION_TO_LOADING
} from 'constants/actionTypes';
import { getTrans } from 'utils/translations';

export const PATIENT_DISEASE_CREATION_NODE = 'DC';

const nodeDiseases = [
  'DA',
  {
    data: {
      mandatory: true,
      category: 'details',
      type: 'group',
      title: 'Browse the condition you want to add',
      i18n: {
        title_pt: 'Selecione a condição que deseja adicionar'
      },
      inline: false,
      completed: false,
      question: {
        id: 0,
        key: 'DISEASE_ID',
        title: 'Browse the condition you want to add',
        block: 'Create condition',
        i18n: {
          title_pt: 'Selecione a condição que deseja adicionar'
        },
        selectedValue: undefined,
        completed: false,
        required: true,
        answer_type: 'single_choice',
        widget: 'select',
        placeholder: 'Type a disease or medical speciality',
        answer_options: {
          options: []
        }
      }
    }
  }
];

const nodeInstitutions = [
  'DB',
  {
    data: {
      mandatory: false,
      category: 'details',
      type: 'group',
      title: 'Choose a healthcare institution',
      i18n: {
        title_pt: 'Escolha uma instituição de saúde'
      },
      inline: false,
      completed: false,
      question: {
        id: 1,
        key: 'INSTITUTION_ID',
        title: 'Choose a healthcare institution',
        i18n: {
          title_pt: 'Escolha uma instituição de saúde'
        },
        block: 'Create condition',
        selectedValue: undefined,
        completed: false,
        required: true,
        placeholder: 'Type a  healthcare institution',
        widget: 'select',
        answer_options: {
          options: []
        }
      }
    }
  }
];

export const getNodeData = node => {
  if (!node) {
    return undefined;
  }

  const [, field] = node;
  return field.data;
};

export const getSelectedValue = (answers, node) => {
  const questionKey = getNodeData(node).question.key;
  return answers[questionKey] ? answers[questionKey][0].value : undefined;
};

// Used to maintain the status of all sections.
const selectNavBarSection = (sections, nodes, current) =>
  sections.map(section => {
    let isCompleted = true;
    let activeNodes = 0;

    // if all active sections are completed, set main section to completed
    for (let i = 0; i < nodes.length; i += 1) {
      if (nodes[i].question.block === section.title) {
        if (!nodes[i].completed) {
          isCompleted = false;
          break;
        }
        activeNodes += 1;
      }
    }

    // if dont have any active nodes in graph tree dont show completed
    if (activeNodes === 0) {
      isCompleted = false;
    }

    return {
      ...section,
      completed: isCompleted,
      selected: section.title === current.question.block
    };
  });

// build sections
const buildSections = (nodes, rootNodeData) =>
  nodes
    .reduce((p, n) => {
      const titles = p.map(section => section.title);
      const data = getNodeData(n);

      if (data.question.block && !titles.includes(data.question.block)) {
        p.push({
          title: data.question.block,
          completed: false,
          selected: false
        });
      }
      return p;
    }, [])
    .map(s => ({
      ...s,
      selected: rootNodeData.question.block === s.title
    }));

export const getSelectedNode = (nodes, current) => {
  const node = nodes.find(n => Object.keys(n)[0] === current);
  if (node) {
    return node[current];
  }
};

const getType = question => {
  if (question.key.includes('INSTRUC')) {
    return 'instruction';
  }
  if (question.family === 'RECOMMENDATION') {
    return 'recommendation';
  }
  return !['AGREEMENT', 'RESUME', 'FINISH'].includes(question.key) ? 'question' : question.key.toLowerCase();
};

const buildNode = (n, answers = []) => {
  const data = getNodeData(n);
  if (data.question.answer_options) {
    data.question.errors = {};
    data.question.answer_options.options = data.question.answer_options.options.map(o => ({
      ...o,
      label: o.title
    }));
  }
  const nodeKey = n[0];
  const completed = answers.includes(data.question.key);

  if (['TRANSITION', 'SECTION'].includes(data.question.key)) {
    return {
      [nodeKey]: [
        nodeKey,
        {
          data: {
            type: data.question.key.toLowerCase(),
            ...data,
            id: nodeKey,
            completed,
            questions: []
          }
        }
      ]
    };
  }

  return {
    [nodeKey]: [
      nodeKey,
      {
        data: {
          ...data,
          id: nodeKey,
          questionTitle: data.question.title,
          type: getType(data.question),
          title: getTrans(data.question, 'title', window.i18next.language),
          questions: [data.question],
          inline: false,
          completed
        }
      }
    ]
  };
};

const nodeSubmitDetails = [
  PATIENT_DISEASE_CREATION_NODE,
  {
    data: {
      key: 'TRANSITION',
      id: 2,
      title: '',
      completed: false,
      loading: false,
      handleEvent: answers => [getSelectedValue(answers, nodeInstitutions), getSelectedValue(answers, nodeDiseases)],
      questions: [],
      question: {
        block: 'Create condition',
        key: 'TRANSITION'
      }
    }
  }
];

const initial = {
  resumeMode: false,
  rootNode: 'DA',
  current: undefined,
  assessmentID: undefined,
  institution: undefined,
  answers: {},
  edges: [],
  nodes: [],
  sections: [],
  scores: [],
  patientScore: [],
  type: undefined
};

export default function (state = initial, action) {
  switch (action.type) {
    case CLEAN_PROM_DATA: {
      return initial;
    }
    case CREATE_PATIENT_DISEASE: {
      const nodes = [nodeDiseases, nodeInstitutions, nodeSubmitDetails].map(n => buildNode(n));

      return {
        ...state,
        nodes,
        edges: [
          ['DA', 'DB'],
          ['DB', PATIENT_DISEASE_CREATION_NODE]
        ],
        current: 'DA',
        rootNode: 'DA',
        sections: [
          {
            title: 'Create condition',
            completed: false,
            selected: true
          }
        ]
      };
    }

    case FETCH_PROM_SECTION:
    case FETCH_PROM_SUCCESS: {
      const answeredNodes = Object.keys(action.payload.answers);
      const nodes = action.payload.nodes.map(n => buildNode(n, answeredNodes));
      const [rootNode] = action.payload.nodes[0];
      const edges = [...action.payload.edges];

      const rootNodeData = action.payload.nodes[0][1].data;
      const newSections = buildSections(action.payload.nodes, rootNodeData);

      const answers = Object.assign(state.answers, action.payload.answers);

      return {
        ...state,
        answers,
        nodes,
        edges,
        current: rootNode,
        assessmentID: action.payload.assessmentID,
        rootNode,
        sections: newSections,
        institution: action.payload.institution || state.institution,
        type: action.payload.type
      };
    }

    case CHANGE_SELECTED_QUESTION: {
      const { id: questionId, questions } = action.payload;
      const nextQuestion = getNodeData(getSelectedNode(state.nodes, questionId));
      const current = getNodeData(getSelectedNode(state.nodes, state.current));

      if (!nextQuestion.completed && !current.completed) {
        return state;
      }

      return {
        ...state,
        current: questionId,
        sections: selectNavBarSection(state.sections, questions, nextQuestion)
      };
    }

    case CHANGE_EXTRA_QUESTION_VALUE:
    case CHANGE_QUESTION_VALUE: {
      const { question, data } = action.payload;

      const newPool = state.nodes.map(p => {
        if (Object.keys(p).includes(action.payload.nodeID)) {
          getNodeData(p[action.payload.nodeID]).completed = false;
        }
        return p;
      });
      if (newPool[0].DA) {
        newPool[0].DA[1].data.questions[0].errors = undefined;
      }

      return {
        ...state,
        answers: {
          ...state.answers,
          [question.key]: [...data]
        },
        nodes: newPool
      };
    }

    case CREATE_PATIENT_DISEASE_ERROR: {
      const newPool = state.nodes.map(p => {
        if (Object.keys(p).includes(PATIENT_DISEASE_CREATION_NODE)) {
          getNodeData(p[PATIENT_DISEASE_CREATION_NODE]).loading = false;
        }
        return p;
      });
      if (newPool[0].DA) {
        newPool[0].DA[1].data.questions[0].errors = { DISEASE_ID: action.payload };
      }

      return {
        ...state,
        nodes: newPool,
        current: 'DA'
      };
    }

    case SET_TRANSITION_TO_LOADING: {
      return {
        ...state,
        nodes: state.nodes.map(p => {
          if (Object.keys(p).includes(action.payload.questionId)) {
            getNodeData(p[action.payload.questionId]).loading = true;
          }
          return p;
        })
      };
    }

    case CHANGE_QUESTION_TO_COMPLETED: {
      const newPool = state.nodes.map(p => {
        if (Object.keys(p).includes(action.payload.questionId)) {
          getNodeData(p[action.payload.questionId]).completed = true;
        }
        return p;
      });

      return {
        ...state,
        nodes: newPool
      };
    }

    case CHANCE_CURRENT_PATH: {
      const { questionId, questions } = action.payload;
      const currentData = getNodeData(getSelectedNode(state.nodes, questionId));
      return {
        ...state,
        sections: selectNavBarSection(state.sections, questions, currentData)
      };
    }

    case FETCH_PROM_PATIENT_DISEASE_INSTITUTION: {
      return {
        ...state,
        institution: action.payload
      };
    }

    case FETCH_ASSESSMENT_SCORE: {
      return {
        ...state,
        resumeMode: true,
        patientScore: action.payload.patientScore
      };
    }

    default:
      return state;
  }
}
