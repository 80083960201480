"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createChartsData = exports.transformRequestToStatistics = exports.transformToClinicalOutcomesStatistics = void 0;
var transformToClinicalOutcomesStatistics = function (coas) { return ({
    coaUid: coas.coaUid,
    statistics: coas.data.map(function (item) { return ({
        id: item.id,
        timing: item.timing,
        averageScore: item.score_average
    }); })
}); };
exports.transformToClinicalOutcomesStatistics = transformToClinicalOutcomesStatistics;
var transformRequestToStatistics = function (items, data) {
    // check if the patientDiseaseID already exists in the array
    var existingItemIndex = items.findIndex(function (item) { return item.coaUid === data.coaUid; });
    if (existingItemIndex !== -1) {
        var updatedItems = __spreadArray([], items, true);
        updatedItems[existingItemIndex].statistics = (0, exports.transformToClinicalOutcomesStatistics)(data).statistics;
        return updatedItems;
    }
    // if the patientDiseaseID doesn't exist, add a new item to the array
    return __spreadArray(__spreadArray([], items, true), [(0, exports.transformToClinicalOutcomesStatistics)(data)], false);
};
exports.transformRequestToStatistics = transformRequestToStatistics;
var createChartsData = function (timings, statistics, coasScores) {
    return coasScores.reduce(function (acc, coaScore) {
        var coaUid = coaScore.coaUid;
        var coaStatistics = statistics.find(function (statistic) { return statistic.coaUid === coaUid; });
        var data = timings
            .filter(function (timing) { var _a; return (_a = timing.linkedCoas) === null || _a === void 0 ? void 0 : _a.some(function (linkedCoa) { return linkedCoa.uid === coaUid; }); })
            .map(function (timing) {
            var name = timing.name, slug = timing.slug;
            var matchingStatistic = coaStatistics === null || coaStatistics === void 0 ? void 0 : coaStatistics.statistics.find(function (statistic) { return statistic.timing.slug === slug; });
            var average = matchingStatistic ? matchingStatistic.averageScore : undefined;
            var matchingScoreItem = coaScore.data.scores.find(function (scoreItem) { var _a; return ((_a = scoreItem.timing) === null || _a === void 0 ? void 0 : _a.slug) === slug; });
            var scoreValue = matchingScoreItem ? matchingScoreItem.score : undefined;
            return { name: name, average: average, score: scoreValue };
        });
        return __spreadArray(__spreadArray([], acc, true), [{ coaUid: coaUid, data: data }], false);
    }, []);
};
exports.createChartsData = createChartsData;
