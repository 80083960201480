import PromQuestionToQuestionReducer from 'Proms/QuestionToQuestion/reducer';
import PatientCoaMeaningReducer from 'Proms/components/Scores/reducers';
import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import AccountReducer from './reducer_account';
import DiseasesReducer from './reducer_diseases';
import DoctorsReducer from './reducer_doctors';
import EducationReducer from './reducer_education';
import EntitiesReducer from './reducer_entities';
import InstitutionsReducer from './reducer_institutions';
import MeasurementsReducer from './reducer_measurements';
import PatientReducer from './reducer_patient';
import ProfessionalReducer from './reducer_professional';
import PromReducer from './reducer_prom';
import ProvidersReducer from './reducer_providers';
import AssessmentReviewsReducer from './reducer_reviews';
import SettingsReducer from './reducer_settings';
import ThemeReducer from './reducer_theme';
import ToastsReducer from './reducer_toasts';
import UserReducer from './reducer_user';
import ActionsBarReducer from './reducer_actions_bar';

export const reducerObject = {
  user: UserReducer,
  patient: PatientReducer,
  professional: ProfessionalReducer,
  providers: ProvidersReducer,
  toasts: ToastsReducer,
  account: AccountReducer,
  doctors: DoctorsReducer,
  institutions: InstitutionsReducer,
  settings: SettingsReducer,
  diseases: DiseasesReducer,
  entities: EntitiesReducer,
  prom: PromReducer,
  reviews: AssessmentReviewsReducer,
  education: EducationReducer,
  measurements: MeasurementsReducer,
  'prom-v2': PromQuestionToQuestionReducer,
  patientCoaMeaning: PatientCoaMeaningReducer,
  theme: ThemeReducer,
  actionsBar: ActionsBarReducer
};

const rootReducer = history => combineReducers(Object.assign(reducerObject, { router: connectRouter(history) }));

export default rootReducer;
