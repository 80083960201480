"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.addFailedValue = exports.addStatisticsToDimension = exports.addScoresToDimension = exports.transformRequestToDimensions = exports.transformToDimension = void 0;
var dates_1 = require("../../../../../../utils/dates");
var transformers_1 = require("../../ScoreWithAverage/slice/transformers");
var transformToDimension = function (data) { return ({
    coaUid: data.coaUid,
    dimensions: data.dimensions.map(function (dimension) { return ({
        name: dimension.name,
        uid: dimension.uid,
        scale: dimension.scale,
        averageScore: undefined,
        evolution: undefined,
        averageEvolution: undefined,
        scores: []
    }); })
}); };
exports.transformToDimension = transformToDimension;
var transformRequestToDimensions = function (items, data) {
    // check if the coaUid already exists in the array
    var existingItemIndex = items.findIndex(function (item) { return item.coaUid === data.coaUid; });
    if (existingItemIndex !== -1) {
        var updatedItems = __spreadArray([], items, true);
        updatedItems[existingItemIndex].dimensions = (0, exports.transformToDimension)(data).dimensions;
        return updatedItems;
    }
    // if the coaUid doesn't exist, add a new item to the array
    return __spreadArray(__spreadArray([], items, true), [(0, exports.transformToDimension)(data)], false);
};
exports.transformRequestToDimensions = transformRequestToDimensions;
var addScoresToDimension = function (items, data) {
    var scores = data.scores, dimensionUid = data.dimensionUid, coaUid = data.coaUid;
    return items.map(function (item) {
        if (item.coaUid === coaUid) {
            return __assign(__assign({}, item), { dimensions: item.dimensions.map(function (dimension) {
                    var _a;
                    if (dimension.uid === dimensionUid) {
                        var updatedDimension = __assign({}, dimension);
                        var updatedScores = scores.map(function (score) { return ({
                            id: score.id,
                            createdAt: score.created_at && (0, dates_1.moment)(score.created_at).format('L'),
                            timing: score.timing,
                            score: score.score
                        }); });
                        // Calculate evolution based on score values
                        var score1 = scores[0].score;
                        var score2 = (_a = scores[1]) === null || _a === void 0 ? void 0 : _a.score;
                        var evolution = (0, transformers_1.calculateEvolution)(score1, dimension.scale, score2);
                        updatedDimension.scores = updatedScores;
                        updatedDimension.evolution = evolution;
                        return updatedDimension;
                    }
                    return dimension;
                }) });
        }
        return item;
    });
};
exports.addScoresToDimension = addScoresToDimension;
var addStatisticsToDimension = function (items, data) {
    var statistics = data.statistics, dimensionUid = data.dimensionUid, coaUid = data.coaUid;
    return items.map(function (item) {
        if (item.coaUid === coaUid) {
            return __assign(__assign({}, item), { dimensions: item.dimensions.map(function (dimension) {
                    var _a, _b;
                    if (dimension.uid === dimensionUid) {
                        var updatedDimension_1 = __assign({}, dimension);
                        var averageScore = 0;
                        var averageEvolution = 0;
                        if ((statistics === null || statistics === void 0 ? void 0 : statistics.length) && updatedDimension_1.scores.length) {
                            var stats = statistics.filter(function (stat) { var _a; return stat.timing.slug === ((_a = updatedDimension_1.scores[0].timing) === null || _a === void 0 ? void 0 : _a.slug); });
                            // @ts-ignore
                            averageEvolution = (0, transformers_1.calculateEvolution)(updatedDimension_1.scores[0].score, updatedDimension_1.scale, (_a = stats[0]) === null || _a === void 0 ? void 0 : _a.score_average);
                            averageScore = (_b = stats === null || stats === void 0 ? void 0 : stats[0]) === null || _b === void 0 ? void 0 : _b.score_average;
                        }
                        updatedDimension_1.averageScore = averageScore;
                        updatedDimension_1.averageEvolution = averageEvolution;
                        return updatedDimension_1;
                    }
                    return dimension;
                }) });
        }
        return item;
    });
};
exports.addStatisticsToDimension = addStatisticsToDimension;
var addFailedValue = function (items, data) {
    var dimensionUid = data.dimensionUid, coaUid = data.coaUid, type = data.type;
    return items.map(function (item) {
        if (item.coaUid === coaUid) {
            return __assign(__assign({}, item), { dimensions: item.dimensions.map(function (dimension) {
                    if (dimension.uid === dimensionUid) {
                        var updatedDimension = __assign({}, dimension);
                        if (type === 'scoresFailure') {
                            updatedDimension.scores = [];
                            updatedDimension.evolution = null;
                        }
                        else {
                            updatedDimension.averageScore = null;
                            updatedDimension.averageEvolution = null;
                        }
                        return updatedDimension;
                    }
                    return dimension;
                }) });
        }
        return item;
    });
};
exports.addFailedValue = addFailedValue;
