import { getLatToString } from 'utils/app';

const _getLaterality = (events = []) => {
  const { laterality } = events.find(e => e.laterality) || {};
  return laterality;
};

const ICONS_MAP = {
  surgery: 'surgery',
  medication: 'medication',
  physiotherapy: 'physio',
  'facial-rehabilitation': 'physio',
  'new-episode': 'newEpisode'
};

const getIcon = key => ICONS_MAP[key] || '';

function Treatment(data) {
  return {
    id: data.id,
    internalId: `${data.id}-treatment`,
    iconName: getIcon(data.treatment.uid),
    key: data.treatment.uid,
    name: data.treatment.name,
    i18n: {
      ...data.treatment.i18n
    },
    laterality: getLatToString(_getLaterality(data.events)),
    events: data.events ? [...data.events] : [],
    status: data.treatment.status,
    answers: { ...data.answers },
    order: data.order,
    primary: data.primary,
    form: data.form.map(field => ({
      key: field.key,
      widget: field.widget,
      title: field.title,
      label: field.title,
      i18n: { ...field.i18n },
      mandatory: field.mandatory,
      answer_options: { ...field.answer_options },
      answer_type: field.answer_type
    })),
    source: 'treatment'
  };
}

export default Treatment;
