"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupReadingsByDate = exports.groupMetricsByType = exports.groupItemsByTypeAndSeverity = void 0;
var dates_1 = require("./dates");
var sortMetrics = function (mapping, items) {
    Object.entries(items).forEach(function (item) {
        return item[1].aggregationItems.sort(function (a, b) { return mapping[b.severity] - mapping[a.severity]; });
    });
    return items;
};
/*
 * Receive groups with items and create aggregations by type and color
 *
 * @param groups
 * @param groupFunction
 */
var groupItemsByTypeAndSeverity = function (groups, groupFunction) {
    var priorityMapping = { 'No problems': 0, Uncategorized: -1, Light: 1, Important: 2, Significant: 3, Urgent: 4 };
    return groups.map(function (group) { return ({
        hours: group.hours,
        items: group.items,
        aggregations: sortMetrics(priorityMapping, groupFunction(group.items))
    }); });
};
exports.groupItemsByTypeAndSeverity = groupItemsByTypeAndSeverity;
var groupMetricsByType = function (items) {
    return items.reduce(function (prev, curr) {
        var _a;
        if (Object.keys(prev).includes(curr.code)) {
            var founded_1 = false;
            prev[curr.code].aggregationItems.forEach(function (value, index) {
                if (value.color === curr.color) {
                    prev[curr.code].aggregationItems[index].count += 1;
                    founded_1 = true;
                }
            });
            if (!founded_1) {
                prev[curr.code].aggregationItems = __spreadArray(__spreadArray([], prev[curr.code].aggregationItems, true), [
                    { color: curr.color, severity: curr.severityLabel, count: 1 }
                ], false);
            }
            return prev;
        }
        return __assign(__assign({}, prev), (_a = {}, _a[curr.code] = {
            label: curr.title,
            aggregationItems: [{ color: curr.color, severity: curr.severityLabel, count: 1 }]
        }, _a));
    }, {});
};
exports.groupMetricsByType = groupMetricsByType;
/**
 * Receives a list of readings and group by days
 *
 * @param today current date
 * @param groups list of groups that have number of hours to group readings
 * @param readings list of readings that we want to group
 */
var groupReadingsByDate = function (today, groups, readings) {
    if (today === void 0) { today = new Date(); }
    return groups.map(function (group) {
        // offset date from today
        var groupDayDate = (0, dates_1.moment)(today).subtract(group.hours, 'hours');
        return {
            hours: group.hours,
            aggregations: {},
            items: readings
                .filter(function (item) { return (0, dates_1.moment)(item.measured_at).isAfter(groupDayDate, 'hour'); })
                .map(function (item) { return ({
                measuredAt: item.measured_at,
                unit: item.unit,
                value: item.values,
                code: item.category,
                severityLabel: item.severity_uid,
                color: item.color,
                title: item.title
            }); })
        };
    });
};
exports.groupReadingsByDate = groupReadingsByDate;
