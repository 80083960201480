import invariant from 'invariant';
import { KEY, LIFECYCLE } from 'redux-pack';

const VALID_KEYS = {
  start: true,
  success: true,
  failure: true,
  finish: true,
  always: true
};

function verifyHandlers(handlers, action) {
  Object.keys(handlers).forEach(key => {
    invariant(
      VALID_KEYS[key],
      `
      The handler for action ${action.type} had a ${key} property defined, but this is not 
      a valid key for a redux-pack handler. Valid keys are: ${Object.keys(VALID_KEYS)}
      `
    );
  });
}

function safeMap(state, fn, action, name) {
  switch (typeof fn) {
    case 'function': {
      fn(state, action);
      break;
    }
    case 'undefined':
      break;
    default:
      // if we've dropped into this case, we've got a problem. Someone is setting
      // things on the handler object they aren't supposed to.
      invariant(
        false,
        `
        The ${name} handler for action ${action.type} is expected to be a function, 
        but found ${typeof fn} instead.
      `
      );
      break;
  }
}

function immerPackHandler(startingState, action, handlers) {
  if (process.env.NODE_ENV === 'development') {
    verifyHandlers(handlers, action);
  }
  const { meta } = action;
  const lifecycle = meta ? meta[KEY.LIFECYCLE] : null;

  if (lifecycle == null) {
    invariant(
      false,
      `
      You used redux-pack's \`handle(...)\` function on the action ${action.type}, however, it
      doesn't appear to be an action that was dispatched by redux-pack. This is likely an error.
      `
    );
    return startingState;
  }

  const state = startingState;
  switch (lifecycle) {
    case LIFECYCLE.START:
      safeMap(state, handlers.start, action, 'start');
      break;
    case LIFECYCLE.SUCCESS:
      safeMap(state, handlers.success, action, 'success');
      safeMap(state, handlers.finish, action, 'finish');
      break;
    case LIFECYCLE.FAILURE:
      safeMap(state, handlers.failure, action, 'failure');
      safeMap(state, handlers.finish, action, 'finish');
      break;
    default:
      // do nothing
      break;
  }
  safeMap(state, handlers.always, action, 'always');
}

export default immerPackHandler;
