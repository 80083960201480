import { moment } from 'utils/dates';
import {
  ACCEPTED_CLINICAL_TRIAL,
  ACCEPTED_CONTEXT_SCREEN,
  CHANGE_NAVIGATION_PREFERENCES_TODOLIST,
  CHANGE_REGIONAL_PREF,
  FETCH_CLINICALTRIALS_SUCCESS,
  FETCH_CONTEXT_SCREEN_SUCCESS,
  FETCH_LANGUAGE_OPTIONS_SUCCESS,
  FETCH_SETTINGS_SUCCESS,
  FETCH_TIMEZONE_OPTIONS_SUCCESS,
  UPDATE_CLINICALTRIALS_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  notifications: {},
  regional: {
    timeZone: '',
    language: undefined
  },
  languageOptions: [],
  timeZoneOptions: [],
  loading: false,
  errors: {},
  lastStatusRequest: undefined,
  clinicalTrials: [],
  contextScreen: {},
  navigationsPreferences: {
    todoList: []
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        notifications: action.payload.notifications,
        regional: action.payload.regional,
        loading: false,
        errors: {},
        lastStatusRequest: 200
      };

    case FETCH_LANGUAGE_OPTIONS_SUCCESS:
      return {
        ...state,
        languageOptions: action.payload,
        lastStatusRequest: 200
      };

    case FETCH_TIMEZONE_OPTIONS_SUCCESS:
      return {
        ...state,
        timeZoneOptions: action.payload,
        lastStatusRequest: 200
      };

    case CHANGE_REGIONAL_PREF: {
      const { language, timeZone } = action.payload;

      moment.locale(language);
      const { L: localeDateFormat } = moment.localeData()._longDateFormat;

      return {
        ...state,
        regional: {
          ...state.regional,
          language: language || state.regional.language,
          timeZone: timeZone || state.regional.timeZone,
          dateFormat: localeDateFormat
        }
      };
    }

    case FETCH_CLINICALTRIALS_SUCCESS:
      return {
        ...state,
        clinicalTrials: action.payload
      };

    case UPDATE_CLINICALTRIALS_SUCCESS:
      return {
        ...state,
        clinicalTrials: action.payload
      };

    case ACCEPTED_CLINICAL_TRIAL:
      return {
        ...state,
        clinicalTrials: state.clinicalTrials.map(ct => {
          /* Checks both clinical trial id and patient id
           because we could have clinical trials with the
           same id from different health spaces, so
           we need to check the patient as well
          */
          if (ct.clinical_trial.id === action.ctid && ct.patient_disease.owner.id === action.patientId) {
            ct.agreed = true;
          }

          return ct;
        })
      };

    case CHANGE_NAVIGATION_PREFERENCES_TODOLIST: {
      const { instID, assessmentType, preferenceType, preferences: newPreferences } = action.payload;
      const index = state.navigationsPreferences.todoList.findIndex(item => item.instID === instID);

      const updatedPreferences = [...state.navigationsPreferences.todoList];

      if (index === -1) {
        const newItem = { instID, preferences: {} };
        newItem.preferences[assessmentType] = { [preferenceType]: {} };
        newItem.preferences[assessmentType][preferenceType] = newPreferences;
        updatedPreferences.push(newItem);
      } else {
        const existingPreferences = updatedPreferences[index].preferences;
        if (!existingPreferences[assessmentType]) {
          existingPreferences[assessmentType] = { [preferenceType]: {} };
        }
        existingPreferences[assessmentType][preferenceType] = newPreferences;
      }

      return {
        ...state,
        navigationsPreferences: {
          todoList: updatedPreferences
        }
      };
    }
    case FETCH_CONTEXT_SCREEN_SUCCESS:
    case ACCEPTED_CONTEXT_SCREEN:
      return {
        ...state,
        contextScreen: action.payload
      };

    default:
      return state;
  }
}
