import {
  FETCH_CAREDOCTOR,
  FETCH_DOCTORS_SUCCESS,
  FETCH_ENTITY_DISEASES_SUCCESS,
  FETCH_GEOLOCATIONS,
  FETCH_INSTITUTIONS_SUCCESS,
  FETCH_INSURANCES_SUCCESS,
  FETCH_SPECIALITIES_SUCCESS
} from 'constants/actionTypes';

export const initialData = {
  diseases: {
    items: []
  },
  doctors: {
    items: []
  },
  careDoctors: {
    items: []
  },
  specialities: {
    items: []
  },
  institutions: {
    items: []
  },
  insurances: {
    items: []
  },
  nationalities: [],
  countries: []
};

export default function (state = initialData, action) {
  switch (action.type) {
    case FETCH_SPECIALITIES_SUCCESS:
      return {
        ...state,
        specialities: {
          items: action.payload
        }
      };
    case FETCH_ENTITY_DISEASES_SUCCESS:
      return {
        ...state,
        diseases: {
          items: action.payload
        }
      };
    case FETCH_DOCTORS_SUCCESS:
      return {
        ...state,
        doctors: {
          items: action.payload
        }
      };

    case FETCH_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        institutions: {
          items: action.payload
        }
      };

    case FETCH_INSURANCES_SUCCESS:
      return {
        ...state,
        insurances: {
          items: action.payload
        }
      };

    case FETCH_GEOLOCATIONS: {
      return {
        ...state,
        nationalities: action.payload.reduce((p, n) => {
          p.push({
            value: n.iso,
            label: n.nationality
          });
          return p;
        }, []),
        countries: action.payload.reduce((p, n) => {
          p.push({
            value: n.iso,
            label: n.name
          });
          return p;
        }, [])
      };
    }

    case FETCH_CAREDOCTOR: {
      return {
        ...state,
        careDoctors: action.payload.reduce((p, n) => {
          p.push({
            value: n.professional.id,
            label: n.professional.name,
            institutionId: n.id
          });
          return p;
        }, [])
      };
    }

    default:
      return state;
  }
}
