const SOURCE = {
  exam: 'exam',
  eventdisease: 'event',
  treatmentdisease: 'treatment'
};

const TAB = {
  exam: 'exams',
  eventdisease: 'assessments',
  treatmentdisease: 'assessments'
};

const ICONS_MAP = {
  surgery: 'surgery',
  'surgery-date': 'surgery',
  'reop-surgery': 'surgery',
  appointment: 'appointment',
  'unplanned-visit': 'appointment',
  'home-visit': 'appointment',
  visit: 'appointment',
  'medication-intake': 'syringe',
  childbirth: 'childbirth',
  'pregnancy-start': 'pregnancy',
  'exam-ultrasound': 'ultrasound',
  medication: 'medication',
  treatment: 'medication',
  physiotherapy: 'physio',
  'facial-rehabilitation': 'physio',
  'new-episode': 'newEpisode',
  'lab-work': 'lab',
  'clinical-assessment': 'doctor-apoint',
  'nursing-assessment': 'nurse-apoint',
  'nutrition-assessment': 'nutrician-apoint',
  death: 'hearth-stop',
  'ablation-date': 'surgery',
  'unplanned-ecg': 'exam',
  reintervention: 'hospitalization',
  'unplanned-holter': 'exam',
  'nac-date': 'exam',
  'sleep-apnea-consultation': 'doctor-apoint',
  'chemo-date': 'doctor-apoint',
  'targeted-therapy': 'doctor-apoint',
  'hormonal-therapy': 'doctor-apoint',
  'paliative-care': 'doctor-apoint',
  'pathological-anatomy': 'doctor-apoint',
  'radiotherapy-date': 'doctor-apoint',
  recurrency: 'doctor-apoint',
  'metastatic-disease': 'doctor-apoint',
  'diagnostic-tests-rhythm-month-1': 'doctor-apoint',
  'diagnostic-tests-rhythm-month-3': 'doctor-apoint',
  'diagnostic-tests-rhythm-month-6': 'doctor-apoint',
  'diagnostic-tests-rhythm-month-12': 'doctor-apoint',
  'diagnostic-tests-rhythm-month-18': 'doctor-apoint',
  'diagnostic-tests-rhythm-month-24': 'doctor-apoint',
  'diagnostic-tests-unplanned-rhythm': 'doctor-apoint',
  'diagnostic-tests-clinical-analysis': 'doctor-apoint',
  'diagnostic-tests-tilt': 'doctor-apoint',
  'diagnostic-tests-imaging': 'doctor-apoint'
};

const getIcon = key => ICONS_MAP[key] || key;

export class ActionBar {
  constructor(data) {
    this.id = data.id;
    this.internalId = data.id;
    this.name = data.name;
    this.type = data.type;
    this.form = data.form;
    this.url = data.url;
    this.order = data.order;
    this.primary = data.primary;
    this.source = SOURCE[data.source];
    this.tab = TAB[data.source];
    this.iconName = getIcon(data.type);
    this.objectID = data.object_id;
  }
}
