"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformRequestToScoreWithAverage = exports.calculateEvolution = void 0;
var dates_1 = require("../../../../../../utils/dates");
function calculateEvolution(score, scale, previousScore) {
    if (previousScore === null) {
        return 0;
    }
    var isBetterScale = scale.best > scale.worst; // Check if higher value is better
    var evolutionDirection = isBetterScale ? 1 : -1; // Set the direction of evolution
    // Compare the score with the previous score and determine the evolution direction
    if (score > previousScore) {
        // Example: scale={best: 100, worse:0 }, score=10 and previousScore=8 => return 1 [positive evolution]
        // Example 2: scale={best: 0, worse:100 }, score=10 and previousScore=8 => return -1 [negative evolution]
        return evolutionDirection;
    }
    else if (score < previousScore) {
        // Example: scale={best: 100, worse:0 }, score=8 and previousScore=10 => return -(-1) [positive evolution]
        // Example 2: scale={best: 0, worse:100 }, score=8 and previousScore=10 => return -1 [negative evolution]
        return -evolutionDirection;
    }
    return 0; // Return 0 for no significant evolution
}
exports.calculateEvolution = calculateEvolution;
var transformToScore = function (data) { return ({
    coaUid: data.coaUid,
    data: {
        scale: { best: undefined, worst: undefined },
        averageScore: undefined,
        evolution: undefined,
        scores: data.scores.map(function (score) { return ({
            id: score.id,
            createdAt: score.created_at && (0, dates_1.moment)(score.created_at).format('L'),
            timing: score.timing,
            score: score.score
        }); })
    }
}); };
var transformRequestToScoreWithAverage = function (items, data) {
    // check if the patientDiseaseID already exists in the array
    var existingItemIndex = items.findIndex(function (item) { return item.coaUid === data.coaUid; });
    if (existingItemIndex !== -1) {
        var updatedItems = __spreadArray([], items, true);
        updatedItems[existingItemIndex].data = transformToScore(data).data;
        return updatedItems;
    }
    // if the patientDiseaseID doesn't exist, add a new item to the array
    return __spreadArray(__spreadArray([], items, true), [transformToScore(data)], false);
};
exports.transformRequestToScoreWithAverage = transformRequestToScoreWithAverage;
