import { getLatToString } from 'utils/app';
import { getTrans } from 'utils/translations';

const ICONS_MAP = {
  'surgery-date': 'surgery',
  'reop-surgery': 'surgery',
  appointment: 'appointment',
  'unplanned-visit': 'appointment',
  visit: 'appointment',
  'medication-intake': 'syringe',
  medication: 'treatment',
  childbirth: 'childbirth',
  'pregnancy-start': 'pregnancy'
};

const getIcon = key => ICONS_MAP[key] || '';

export const _getValue = (form, item, answers, lang) => {
  const { type } = item.option;
  const { field } = item.option;

  if (type === 'choice' && answers[form.key]) {
    const [{ choice }] = answers[form.key];
    if (choice) {
      const option = form.answer_options.options.find(o => o.key === choice);
      return getTrans(option, field, lang);
    }
  }
  return undefined;
};

function Event(data) {
  let form = [];
  if (data.form) {
    form = data.form.map(field => ({
      key: field.key,
      description: field.description ? { ...field.description } : {},
      widget: field.widget,
      title: field.title,
      i18n: { ...field.i18n },
      mandatory: field.mandatory,
      answer_options: { ...field.answer_options },
      answer_type: field.answer_type
    }));
  }

  return {
    id: data.id,
    internalId: `${data.id}-event`,
    iconName: getIcon(data.event.uid),
    name: data.event.name,
    uid: data.event.uid,
    event_at: data.event_at,
    laterality: data.laterality,
    i18n: {
      ...data.event.i18n
    },
    answers: { ...data.answers },
    detail: { ...data.detail },
    order: data.order,
    primary: data.primary,
    form,
    userSource: data.source,
    source: 'event',
    getEventDescription(lang) {
      if (this.form.length > 0) {
        return this.form.reduce((p, n) => {
          if (Object.keys(n.description).length > 0) {
            const value = _getValue(n, n.description, this.answers, lang);
            if (value) {
              p.push(`${value}`);
            }
          }
          return p;
        }, []);
      } else if (this.laterality) {
        return [`${window.i18next.t('Laterality')} - ${window.i18next.t(getLatToString(this.laterality))}`];
      }
      return [];
    }
  };
}

export default Event;
