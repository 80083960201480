import {
  FETCH_PATIENT_DATA,
  FETCH_PATIENT_HISTORY,
  FETCH_PATIENT_LAST_ASSESSMENT,
  FETCH_PATIENT_REPORTED_SYMPTOMS,
  FETCH_PATIENT_SCORES,
  FETCH_PATIENT_TIMELINE_ASSESSMENTS,
  FETCH_PATIENT_TODO_ASSESSMENTS,
  SELECT_PATIENT_COA,
  SELECT_PATIENT_INSTITUTION,
  SET_PATIENT_INSTITUTIONS,
  UPDATE_PATIENT_DISEASES
} from 'constants/actionTypes';
import { groupItemsByTypeAndSeverity, groupMetricsByType, groupReadingsByDate } from 'utils/aggregationUtils';
import { PatientScore } from '../models/Score';
import { groupSymptomsByDate, groupSymptomsByType } from '../utils/groupReportedSymptoms';

const initialState = {
  institutions: [],
  selectedInstitution: {},
  selectedCoa: {},
  patientScores: [],
  todoAssessments: [],
  assessments: [],
  diseasePatientHistory: [],
  lastAssessment: {},
  reportedSymptoms: []
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_PATIENT_DATA:
      return {
        ...state,
        ...payload
      };
    case UPDATE_PATIENT_DISEASES:
      return {
        ...state,
        diseases: [...state.diseases, payload.disease]
      };

    case SET_PATIENT_INSTITUTIONS: {
      const { institutions, selected } = action.payload;

      return {
        ...state,
        institutions,
        selectedInstitution: selected
      };
    }

    case SELECT_PATIENT_COA: {
      const patientCoa = action.payload.score || [];

      return {
        ...state,
        selectedCoa: patientCoa.length ? new PatientScore(patientCoa[0]) : {}
      };
    }

    case SELECT_PATIENT_INSTITUTION:
      return {
        ...state,
        selectedInstitution: state.institutions.find(inst => inst.id === action.payload) || {}
      };

    case FETCH_PATIENT_SCORES: {
      const { scores } = action.payload;

      return {
        ...state,
        patientScores: scores ? scores.map(s => new PatientScore(s)) : []
      };
    }

    case FETCH_PATIENT_TIMELINE_ASSESSMENTS: {
      const { timelineAssessments } = action.payload;
      return {
        ...state,
        assessments: timelineAssessments.length ? timelineAssessments : []
      };
    }

    case FETCH_PATIENT_TODO_ASSESSMENTS: {
      const { todoAssessments } = action.payload;
      return {
        ...state,
        todoAssessments: todoAssessments.length ? todoAssessments : []
      };
    }
    case FETCH_PATIENT_LAST_ASSESSMENT: {
      const { lastAssessment } = action.payload;

      return {
        ...state,
        lastAssessment: lastAssessment.length ? lastAssessment : {}
      };
    }

    case FETCH_PATIENT_HISTORY: {
      const groups = [
        { hours: 24, items: [], aggregations: {} },
        { hours: 48, items: [], aggregations: {} },
        { hours: 720, items: [], aggregations: {} }
      ];
      const diseasePatientHistory = action.payload.length
        ? groupItemsByTypeAndSeverity(groupReadingsByDate(new Date(), groups, action.payload), groupMetricsByType)
        : groups;

      return {
        ...state,
        diseasePatientHistory
      };
    }
    case FETCH_PATIENT_REPORTED_SYMPTOMS: {
      const groups = [
        { hours: 24, items: [], aggregations: {} },
        { hours: 48, items: [], aggregations: {} },
        { hours: 720, items: [], aggregations: {} }
      ];
      const reportedSymptoms = action.payload.length
        ? groupItemsByTypeAndSeverity(groupSymptomsByDate(new Date(), groups, action.payload), groupSymptomsByType)
        : groups;
      return {
        ...state,
        reportedSymptoms
      };
    }
    default:
      return state;
  }
}
