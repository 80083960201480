import { formatDecimalCases } from 'utils/app';
import { moment } from 'utils/dates';

export function Score(data, decimalCases) {
  const date = data.date && moment(data.date).format('L');
  return {
    id: data.id,
    serverDate: data.date,
    promKey: data.prom_key,
    score: formatDecimalCases(data.score, decimalCases),
    assessmentId: data.assessment_id,
    averageScore: data.score_average,
    date,
    timing: data.timing,
    scale: {
      best: data.scale.best,
      worst: data.scale.worst
    }
  };
}

export function PatientScore(data) {
  return {
    name: data.name,
    uid: data.uid,
    short_description: data.short_description,
    description: data.description,
    scores: data.scores.map(s => new Score(s, data.decimal_cases))
  };
}

export const getScales = promKey => {
  const promConfigs = {
    CATQUEST: {
      scales: [
        {
          scale: [0, 33],
          icon: 'icon-smileNeutral',
          color: '' // TODO
        },
        {
          scale: [33, 66],
          icon: 'icon-smileHappy',
          color: ''
        },
        {
          scale: [66, 100],
          icon: 'icon-smileVeryHappy',
          color: ''
        }
      ]
    }
  };

  return promConfigs[promKey] ? promConfigs[promKey].scales : undefined;
};
