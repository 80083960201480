import {
  FETCH_EDUCATION_ARTICLES,
  FETCH_EDUCATION_BANNERS,
  FETCH_SECTIONS,
  FETCH_WARNING_SIGNS,
  RECOMMENDED_ARTICLES,
  RESET_EDUCATION,
  SEARCH_EDUCATION_ARTICLES,
  SELECT_EDUCATION_TAB
} from '../constants/actionTypes';

export const initialState = {
  articles: {},
  banners: [],
  searchedArticles: [],
  warningSigns: [],
  tab: undefined,
  sections: {},
  recommended: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SECTIONS: {
      const items = action.payload.sort((a, b) => a.id - b.id);
      return {
        ...state,
        sections: items.reduce(
          (p, n) => ({
            ...p,
            [n.id]: n
          }),
          {}
        )
      };
    }

    case RECOMMENDED_ARTICLES: {
      return {
        ...state,
        recommended: action.payload
      };
    }

    case FETCH_EDUCATION_ARTICLES: {
      return { ...state, articles: { ...state.articles, ...action.payload } };
    }
    case FETCH_EDUCATION_BANNERS: {
      return { ...state, banners: [...state.banners, ...action.payload] };
    }
    case SEARCH_EDUCATION_ARTICLES: {
      const newState = { ...state, searchedArticles: [...action.payload] };
      return newState;
    }
    case FETCH_WARNING_SIGNS: {
      const newState = { ...state, warningSigns: [...action.payload] };
      return newState;
    }
    case SELECT_EDUCATION_TAB: {
      return {
        ...state,
        tab: action.payload
      };
    }
    case RESET_EDUCATION:
      return initialState;

    default:
      return state;
  }
}
