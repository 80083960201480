import { QUESTION_SUBMISSION_STATUS, QUESTION_TYPE } from './constants';

const QUESTION_KEY_TO_QUESTION_TYPE_MAP = {
  TRANSITION: QUESTION_TYPE.TRANSITION,
  SECTION: QUESTION_TYPE.SECTION,
  AGREEMENT: QUESTION_TYPE.AGREEMENT,
  RESUME: QUESTION_TYPE.RESUME,
  FINISH: QUESTION_TYPE.FINISH
};

const NON_QUESTION_KEYS = ['TRANSITION', 'SECTION', 'AGREEMENT', 'RESUME', 'FINISH'];

/**
 * Calculates the type of a question
 * @param question
 * @returns {QUESTION_TYPE}
 */
const getQuestionType = question => {
  if (question.key.includes('INSTRUC')) {
    return QUESTION_TYPE.INSTRUCTION;
  }

  if (question.family === 'RECOMMENDATION') {
    return QUESTION_TYPE.RECOMMENDATION;
  }

  if (NON_QUESTION_KEYS.includes(question.key)) {
    return QUESTION_KEY_TO_QUESTION_TYPE_MAP[question.key];
  }

  return QUESTION_TYPE.QUESTION;
};

export const parseNodeToQuestion = ({
  question,
  id,
  mandatory,
  validationNode,
  isValid = false,
  isSubmittableOnChange = true
}) => ({
  id: question.id,
  nodeId: id,
  validationNode,
  key: question.key,
  title: question.title,
  widget: question.widget,
  family: question.family,
  hint: question.hint,
  context: question.context,
  block: question.block,
  event: question.event,
  exam: question.exam,
  i18n: question.i18n,
  uiConfig: question.ui_config,
  imageUrl: question.image_url,
  completed: false,
  type: getQuestionType(question),
  answer: {
    type: question.answer_type,
    options: question.answer_options ? question.answer_options.options : [],
    extra: question.answer_extra || {}
  },
  validation: {
    isValid,
    errors: []
  },
  isSubmittableOnChange,
  submissionStatus: QUESTION_SUBMISSION_STATUS.INITIAL,
  mandatory,
  nextQuestionId: null,
  questionIndex: null
});

export const buildAnswer = ({ key, data, alreadySubmitted = false }) => ({
  [key]: {
    data,
    alreadySubmitted
  }
});

export const buildQuestion = node => {
  const question = parseNodeToQuestion(node);
  return { question };
};

/**
 * Parses an object with the following schema:
 * {
 *   QUESTION_KEY: {
 *     data: [{ choice: 2 }],
 *     prop1: null,
 *     prop2: 12,
 *     ...
 *   },
 *   QUESTION_KEY_2: {
 *     data: [{ choice: 10 }],
 *     otherProps: 123,
 *     ...
 *   }
 * }
 * to the expected answer schema:
 * {
 *   QUESTION_KEY: [{ choice: 2 }],
 *   QUESTION_KEY_2: [{ choice: 10 }]
 * }
 * @param answers
 * @returns {object}
 */
export const parseAnswersToApi = answers =>
  Object.entries(answers).reduce(
    (p, c) => ({
      ...p,
      [c[0]]: c[1] && c[1].data
    }),
    {}
  );
