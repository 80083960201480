import produce from 'immer';
import { getRandomString } from 'utils/app';

import { ADD_TOAST, REMOVE_TOAST } from '../constants/actionTypes';

export const initialState = [];

const reducer = produce((draft, action) => {
  switch (action.type) {
    case ADD_TOAST:
      if (!action.payload.id) {
        action.payload.id = getRandomString();
      }

      if (draft.length >= 3) {
        draft.shift();
      }

      draft.push(action.payload);
      break;
    case REMOVE_TOAST:
      draft.splice(
        draft.findIndex(toast => toast.id === action.payload.id),
        1
      );
      break;
    default:
    // do nothing
  }
}, initialState);

export default reducer;
