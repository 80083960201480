"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterMoreUrgentSymptoms = exports.groupSymptomsByType = exports.groupSymptomsByDate = void 0;
var dates_1 = require("../utils/dates");
/**
 * Receives a list of readings and group by days
 *
 * @param today current date
 * @param groups list of groups that have number of hours to group readings
 * @param readings list of readings that we want to group
 */
var groupSymptomsByDate = function (today, groups, readings) {
    if (today === void 0) { today = new Date(); }
    return groups.map(function (group) {
        // offset date from today
        var groupDayDate = (0, dates_1.moment)(today).subtract(group.hours, 'hours');
        return {
            hours: group.hours,
            aggregations: {},
            items: readings
                .filter(function (item) { return (0, dates_1.moment)(item.created_at).isAfter(groupDayDate, 'hour'); })
                .map(function (item) { return ({
                reportedAt: item.updated_at,
                uid: item.uid,
                name: item.name,
                educationalLink: item.educational_link,
                severityLabel: item.severity_level_name,
                color: item.severity_level_color
            }); })
        };
    });
};
exports.groupSymptomsByDate = groupSymptomsByDate;
var groupSymptomsByType = function (items) {
    return items.reduce(function (prev, curr) {
        var _a;
        if (Object.keys(prev).includes(curr.uid)) {
            var founded_1 = false;
            prev[curr.uid].aggregationItems.forEach(function (value, index) {
                if (value.color === curr.color) {
                    prev[curr.uid].aggregationItems[index].count += 1;
                    founded_1 = true;
                }
            });
            if (!founded_1) {
                prev[curr.uid].aggregationItems = __spreadArray(__spreadArray([], prev[curr.uid].aggregationItems, true), [
                    { color: curr.color, severity: curr.severityLabel, count: 1 }
                ], false);
            }
            return prev;
        }
        return __assign(__assign({}, prev), (_a = {}, _a[curr.uid] = {
            label: curr.name,
            educationalLink: curr.educationalLink,
            aggregationItems: [{ color: curr.color, severity: curr.severityLabel, count: 1 }]
        }, _a));
    }, {});
};
exports.groupSymptomsByType = groupSymptomsByType;
var filterMoreUrgentSymptoms = function (groups) {
    return groups.map(function (group) { return ({
        hours: group.hours,
        items: group.items.filter(function (symptom) { return symptom.severityLabel === 'Urgent' || symptom.severityLabel === 'Important'; }),
        aggregations: Object.entries(group.aggregations).reduce(function (acc, _a) {
            var key = _a[0], value = _a[1];
            var filteredItems = value.aggregationItems.filter(function (item) { return item.severity === 'Urgent' || item.severity === 'Important'; });
            if (filteredItems.length > 0) {
                acc[key] = __assign(__assign({}, value), { aggregationItems: filteredItems });
            }
            return acc;
        }, {})
    }); });
};
exports.filterMoreUrgentSymptoms = filterMoreUrgentSymptoms;
