import { FETCH_PROFESSIONALS_SUCCESS } from 'constants/actionTypes';

const initialState = {
  items: [],
  loading: false,
  errors: {},
  lastStatusRequest: undefined
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROFESSIONALS_SUCCESS:
      return {
        ...state,
        items: action.payload.map(i => ({
          ...i,
          src: i.avatar_url
        })),
        loading: false,
        errors: {},
        lastStatusRequest: 200
      };
    default:
      return state;
  }
}
