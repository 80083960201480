class HealthSpace {
  constructor(data) {
    this.url = data.url ? `${this.getHttpProtocol()}${data.url}` : '';
    this.name = data.name;
    this.avatar = data.avatar;
    this.patient = data.patient;
    this.institutions = data.institutions;
  }

  getHttpProtocol = () => (process.env.NODE_ENV === 'production' ? 'https://' : 'http://');

  setPatient = patient => {
    this.patient = patient;
  };
}

export default HealthSpace;
