import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { getShortName } from 'utils/app';
import {
  ENHANCE_HEALTH_SPACE_DATA,
  FETCH_USER_DATA,
  RESET_NOTIFICATIONS,
  SET_HEALTH_SPACE,
  USER_AVATAR_CHANGE,
  USER_PHONE_VALIDATED,
  USER_UPDATE_DATA,
  USER_UPDATE_DATA_BEGINS,
  USER_UPDATE_DATA_ERROR
} from '../constants/actionTypes';
import HealthSpace from '../models/HealthSpace';

const initial = {
  name: '',
  loading: false,
  errors: {},
  lastStatusRequest: undefined,
  selectedRole: undefined,
  helpscout: {
    mappings: {},
    signatures: {}
  },
  hasUsedMobile: false
};

export default function (state = null, action) {
  let newState;

  switch (action.type) {
    case FETCH_USER_DATA: {
      const { health_spaces: healthSpaces = [], helpscout = {} } = action.payload;

      newState = Object.assign(action.payload, initial);
      newState.name = action.payload.private_data.name;
      newState.shortName = getShortName(action.payload.private_data.name);
      newState.role = action.payload.role;
      // TODO: remove this object instantiation and use a js object
      newState.healthSpaces = healthSpaces.map(hs => new HealthSpace(hs));
      newState.helpscout = {
        mappings: helpscout.mappings || {},
        signatures: helpscout.signatures || {}
      };
      newState.hasUsedMobile = action.payload.has_used_mobile || false;
      return newState;
    }

    case ENHANCE_HEALTH_SPACE_DATA: {
      const { healthSpace, patient, institutions } = action.payload;
      return {
        ...state,
        healthSpaces: (state.healthSpaces || []).map(hs => {
          if (hs.url === healthSpace.url) {
            return {
              ...hs,
              patient,
              institutions
            };
          }
          return hs;
        })
      };
    }

    case SET_HEALTH_SPACE: {
      const selected = state.healthSpaces.find(hs => hs.url === action.payload.url);
      if (!selected) {
        return state;
      }

      return {
        ...state,
        selectedHealthSpace: selected
      };
    }

    case USER_UPDATE_DATA_BEGINS:
      newState = { ...state };
      newState.loading = true;
      newState.errors = {};
      return newState;

    case USER_UPDATE_DATA: {
      const user = Object.assign(action.payload, {
        ...state,
        name: action.payload.private_data.name,
        shortName: getShortName(action.payload.private_data.name),
        loading: false,
        errors: {},
        lastStatusRequest: 200,
        private_data: action.payload.private_data
      });
      return user;
    }

    case USER_AVATAR_CHANGE:
      return {
        ...state,
        private_data: {
          ...state.private_data,
          avatar_url: action.payload
        }
      };

    case USER_UPDATE_DATA_ERROR:
      newState = { ...state };
      newState.loading = false;
      newState.errors = action.payload;
      newState.lastStatusRequest = 400;
      return newState;

    case USER_PHONE_VALIDATED: {
      const phone = parsePhoneNumberFromString(action.payload.phone).formatNational().replace(/\s/g, '');
      return {
        ...state,
        is_phone_verified: true,
        phone,
        pending_verification: {
          ...state.pending_verification,
          phone: ''
        }
      };
    }
    case RESET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: {
          unread: 0
        }
      };
    }

    default:
      return state;
  }
}
