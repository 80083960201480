import { FETCH_DOCTORS_SUCCESS } from '../constants/actionTypes';

const initialState = {
  items: [],
  loading: false,
  errors: {},
  lastStatusRequest: undefined
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_DOCTORS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        loading: false,
        errors: {},
        lastStatusRequest: 200
      };
    default:
      return state;
  }
}
