import {
  CLEAN_ACCOUNT_SETTINGS_ERRORS,
  UPDATE_ACCOUNT_SETTINGS_BEGINS,
  UPDATE_ACCOUNT_SETTINGS_ERROR,
  UPDATE_ACCOUNT_SETTINGS_SUCCESS
} from '../constants/actionTypes';

const defaultData = {
  settings: {},
  loading: false,
  errors: {},
  lastStatusRequest: undefined
};

export default function (state = defaultData, action) {
  let newState;
  switch (action.type) {
    case UPDATE_ACCOUNT_SETTINGS_SUCCESS:
      newState = { ...state };
      newState.lastStatusRequest = 200;
      return { ...newState };

    case UPDATE_ACCOUNT_SETTINGS_BEGINS:
      newState = { ...state };
      newState.loading = true;
      newState.errors = {};
      newState.lastStatusRequest = undefined;
      return { ...newState };

    case UPDATE_ACCOUNT_SETTINGS_ERROR: {
      newState = { ...state };
      newState.lastStatusRequest = 400;
      const { fields } = action.payload.form;
      // map server errors to model data
      const errors = Object.keys(fields).reduce((prev, next) => {
        if (fields[next].errors.length > 0) {
          [prev[next]] = fields[next].errors;
        }
        return prev;
      }, {});

      newState.errors = errors;
      return { ...newState };
    }

    case CLEAN_ACCOUNT_SETTINGS_ERRORS:
      newState = { ...state };
      action.payload.forEach(key => delete newState.errors[key]);
      return { ...newState };

    default:
      return state;
  }
}
