class DiseaseAlert {
  constructor(data) {
    this.message = data.message;
    this.uid = data.alert_uid;
    this.source = data.source;
    this.active = data.is_active;
  }

  setActive(status) {
    this.active = status;
  }
}

export default DiseaseAlert;
