import {
  ADD_NEW_SELECTED_EVENT,
  ADD_TEAM_MEMBER,
  CHANGE_CARE_DOCTOR,
  CHANGE_TAG_WARNINGS_STATUS,
  DELETE_DISEASE,
  DELETE_DISEASE_FAILER,
  DISEASE_EVENTS_TIMELINE,
  DISEASE_TEAM_MEMBERS,
  FEATCH_ACTION_BAR_ITEMS,
  FETCH_DEVELOPMENT_INFO_SUCCESS,
  FETCH_DISEASES,
  FETCH_DISEASE_BY_ID,
  FETCH_DISEASE_EVENTS,
  FETCH_DISEASE_SCORES,
  FETCH_EXAMS_OPTIONS_SUCCESS,
  FETCH_PATIENT_DISEASE_DATA,
  FETCH_PREGNANCY_BASE_DATE,
  FETCH_PREGNANCY_MOVEMENT_REPORT,
  FETCH_READINGS,
  FETCH_READINGS_RANGES,
  FETCH_SELECTED_EVENTS,
  FETCH_SELECTED_TREATMENTS,
  FETCH_STATUS_BAR_ANSWER,
  FETCH_STATUS_BAR_WARNINGS,
  FETCH_TREATMENT_OPTIONS_SUCCESS,
  LOADING_ADD_TEAM_MEMBER,
  PROM_SELECT_DISEASE,
  REMOVED_TEAM_MEMBER,
  RESET_SELECTED_PATIENT,
  RESET_STATUS_BAR,
  SET_SELECTED_DISEASE,
  UPDATED_DISEASE_INSTITUTION,
  UPDATE_TIMELINE,
  UPDATE_TIMELINE_LOADING,
  UPDATE_TREATMENT_EVENT
} from 'constants/actionTypes';
import MeasurementModel from 'models/Measurement';
import { ActionBar } from '../models/ActionBar';
import DiseaseAlert from '../models/DiseaseAlert';
import Event from '../models/Event';
import EventTimeline from '../models/EventTimeline';
import { Exam } from '../models/Exam';
import { PatientScore } from '../models/Score';
import Treatment from '../models/Treatment';

const initialState = {
  items: [],
  loading: undefined,
  selected: {},
  events: [],
  status: {
    timelineLoading: false
  },
  pregnancy: {
    movementReport: {}
  },
  examsOptions: [],
  treatmentsOptions: [],
  selectedTreatmentEvents: [],
  selectedEvents: [],
  statusBar: {
    questions: [],
    warnings: []
  },
  readings: [],
  pdisease: {},
  entries: [],
  images: [],
  developmentInfo: {},
  pregnancyBaseDate: undefined,
  actionBar: [],
  eventsTimeline: []
};

const _getEventAt = item => {
  if (item.source === 'event') {
    return item.event_at;
  } else if (item.source === 'treatment' && item.events.length > 0) {
    return item.events[0].event_at;
  }
};

const sortEvents = (a, b) => {
  const dateA = _getEventAt(a);
  const dateB = _getEventAt(b);

  const diff = new Date(dateB) - new Date(dateA);
  // if same day order by id
  if (diff === 0) {
    return b.id > a.id ? 1 : -1;
  }
  return diff;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_DISEASES:
      return {
        ...state,
        items: action.payload,
        treatmentsOptions: [],
        selectedTreatmentEvents: [],
        selectedEvents: []
      };

    case FETCH_DISEASE_BY_ID: {
      const { pdisease, update } = action.payload;

      return {
        ...state,
        items: update ? state.items.map(d => (d.id === pdisease.id ? pdisease : d)) : [{ ...pdisease }],
        selected: {
          ...pdisease
        },
        treatmentsOptions: [],
        selectedTreatmentEvents: [],
        selectedEvents: []
      };
    }

    case SET_SELECTED_DISEASE: {
      const { conditionID } = action.payload;
      const selectedItem = state.items.find(item => String(item.id) === String(conditionID));
      return {
        ...state,
        selected: selectedItem
      };
    }

    case FETCH_DISEASE_SCORES: {
      const { pdisease, coaScores } = action.payload;
      let { selected } = state;

      if (String(state.selected.id) === String(pdisease.id)) {
        selected = {
          ...selected,
          scores: coaScores?.length
            ? coaScores.filter(item => !item.is_dimension).map(score => new PatientScore(score))
            : []
        };
      }

      return {
        ...state,
        items: state.items.map(i => {
          if (String(i.id) === String(pdisease.id)) {
            return {
              ...i,
              scores: coaScores && coaScores.length ? coaScores.map(score => new PatientScore(score)) : []
            };
          }
          return i;
        }),
        selected
      };
    }

    case DISEASE_TEAM_MEMBERS: {
      return {
        ...state,
        items: state.items.map(pdisease => {
          if (pdisease.id === action.payload.pdiseaseID) {
            return {
              ...pdisease,
              team_members: action.payload.items
            };
          }
          return pdisease;
        })
      };
    }

    case PROM_SELECT_DISEASE:
      return {
        ...state,
        items: [...state.items, action.payload],
        selected: { ...action.payload }
      };
    case FETCH_DISEASE_EVENTS: {
      return {
        ...state,
        events: action.payload.map(e => new Event(e))
      };
    }

    case FETCH_SELECTED_EVENTS: {
      const items = action.payload.map(e => new Event(e));
      return {
        ...state,
        selectedEvents: [...items.sort(sortEvents)]
      };
    }

    case UPDATE_TIMELINE_LOADING:
      return {
        ...state,
        status: {
          ...state.status,
          timelineLoading: action.payload
        }
      };

    case UPDATE_TIMELINE:
      return {
        ...state,
        status: {
          ...state.status,
          timelineLoading: false
        },
        selected: {
          ...state.selected,
          timeline: action.payload
        }
      };

    case DELETE_DISEASE:
      return state.filter(u => u.id !== action.payload);
    case DELETE_DISEASE_FAILER:
      return state;
    case UPDATED_DISEASE_INSTITUTION: {
      const newSelected = { ...state.selected };
      newSelected.institution = action.payload.institution;
      return {
        ...state,
        selected: {
          ...state.selected,
          institution: action.payload.institution,
          institution_team: action.payload.institution_team
        }
      };
    }
    case REMOVED_TEAM_MEMBER: {
      const newTeamMembers = state.selected.team_members.filter(tm => tm.professional.id !== action.payload);
      return {
        ...state,
        loading: undefined,
        selected: {
          ...state.selected,
          team_members: newTeamMembers
        }
      };
    }
    case LOADING_ADD_TEAM_MEMBER: {
      return {
        ...state,
        loading: LOADING_ADD_TEAM_MEMBER
      };
    }

    case ADD_TEAM_MEMBER: {
      if (action.payload === '') {
        return {
          ...state,
          loading: undefined
        };
      }

      const newTeamMembers = [...state.selected.team_members, action.payload];
      return {
        ...state,
        loading: undefined,
        selected: {
          ...state.selected,
          team_members: newTeamMembers
        }
      };
    }

    case CHANGE_CARE_DOCTOR: {
      return {
        ...state,
        selected: {
          ...state.selected,
          care_doctor: action.payload
        }
      };
    }

    case FETCH_SELECTED_TREATMENTS: {
      return {
        ...state,
        selectedTreatmentEvents: [
          ...action.payload.treatments.map(t => new Treatment(t)),
          ...action.payload.events.map(e => new Event(e))
        ].sort(sortEvents)
      };
    }

    case FETCH_TREATMENT_OPTIONS_SUCCESS: {
      const newTreatmentOptions = action.payload.map(t => new Treatment(t));
      return {
        ...state,
        treatmentsOptions: newTreatmentOptions
      };
    }

    case DISEASE_EVENTS_TIMELINE: {
      return {
        ...state,
        eventsTimeline: action.payload.map(e => new EventTimeline(e))
      };
    }

    case FETCH_EXAMS_OPTIONS_SUCCESS:
      return {
        ...state,
        examsOptions: action.payload.map(e => new Exam(e))
      };

    case ADD_NEW_SELECTED_EVENT: {
      const { source, data } = action.payload;
      const item = source === 'treatment' ? new Treatment(data) : new Event(data);
      return {
        ...state,
        selectedTreatmentEvents: [...state.selectedTreatmentEvents, item].sort(sortEvents)
      };
    }

    case UPDATE_TREATMENT_EVENT: {
      const { source, data } = action.payload;
      return {
        ...state,
        selectedTreatmentEvents: state.selectedTreatmentEvents
          .map(e => {
            // because we have events and treatments in one variable we can have the same id
            // of different types, so we need to check
            if (e.id === data.id && e.source === source) {
              const item = source === 'treatment' ? new Treatment(data) : new Event(data);
              return {
                ...item
              };
            }
            return e;
          })
          .sort(sortEvents)
      };
    }
    case FETCH_PREGNANCY_MOVEMENT_REPORT: {
      const nState = {
        ...state,
        pregnancy: {
          ...state.pregnancy,
          movementReport: {
            date: action.payload.date
          }
        }
      };
      return nState;
    }

    case FETCH_STATUS_BAR_ANSWER: {
      return {
        ...state,
        statusBar: {
          ...state.statusBar,
          questions: action.payload.map(q => ({
            value: q.value,
            label: q.label,
            type: q.value_type
          }))
        }
      };
    }

    case FETCH_STATUS_BAR_WARNINGS: {
      return {
        ...state,
        statusBar: {
          ...state.statusBar,
          warnings: action.payload.map(data => new DiseaseAlert(data))
        }
      };
    }

    case CHANGE_TAG_WARNINGS_STATUS: {
      const { uid } = action.payload;
      return {
        ...state,
        statusBar: {
          ...state.statusBar,
          warnings: state.statusBar.warnings.map(w => {
            if (w.uid === uid) {
              w.setActive(!w.active);
            }
            return w;
          })
        }
      };
    }

    case RESET_STATUS_BAR: {
      return {
        ...state,
        statusBar: {
          ...state.statusBar,
          questions: [],
          warnings: []
        }
      };
    }

    case FETCH_DEVELOPMENT_INFO_SUCCESS: {
      const { payload } = action;
      return { ...state, developmentInfo: { ...payload } };
    }

    case FETCH_PREGNANCY_BASE_DATE: {
      return { ...state, pregnancyBaseDate: action.payload };
    }

    case FEATCH_ACTION_BAR_ITEMS: {
      return { ...state, actionBar: action.payload.map(data => new ActionBar(data)) };
    }

    case RESET_SELECTED_PATIENT: {
      return initialState;
    }

    case FETCH_READINGS:
      return {
        ...state,
        readings: action.payload.map(reading => new MeasurementModel(reading))
      };

    case FETCH_PATIENT_DISEASE_DATA:
      return {
        ...state,
        pdisease: action.payload
      };

    case FETCH_READINGS_RANGES:
      return {
        ...state,
        ranges: action.payload
      };

    default:
      return state;
  }
}
