"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformToClinicalOutcomes = void 0;
var transformToClinicalOutcomes = function (coas) {
    return coas
        .filter(function (coa) { return !coa.is_dimension; })
        .map(function (item) {
        var _a;
        return ({
            uid: item.uid,
            name: item.name,
            shortDescription: item.short_description,
            description: item.description,
            scale: item.scale,
            decimalCases: (_a = item.decimal_cases) !== null && _a !== void 0 ? _a : undefined
        });
    });
};
exports.transformToClinicalOutcomes = transformToClinicalOutcomes;
