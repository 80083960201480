import { moment } from 'utils/dates';
import { VALIDATION_ERRORS } from './constants';

const _getValue = answer => {
  const [{ value }] = answer;
  return value;
};

const _hasValidText = answer => {
  if (!answer || answer.length === 0) {
    return false;
  }
  return !!_getValue(answer);
};

// eslint-disable-next-line no-restricted-globals
const _isNumeric = v => !isNaN(parseFloat(v)) && isFinite(v);

const _hasValidNumber = answer => {
  if (!answer || answer.length === 0) {
    return false;
  }
  return _isNumeric(_getValue(answer));
};

const _hasValidDate = answer => {
  if (!answer || answer.length === 0) {
    return false;
  }

  const dateValue = _getValue(answer);
  if (!dateValue) {
    return false;
  }
  return moment(dateValue).isValid() && moment().diff(dateValue, 'years') <= 100;
};

const _hasSingleOption = answers => !(answers.length === 0 || answers.length > 1);

const _hasMultipleOptions = answers => answers.length > 0;

const _hasValidOption = (options, answers) => {
  const keys = options.map(o => o.key);
  const [answer] = answers;
  if (!answer) {
    return false;
  }
  return keys.includes(answer.choice);
};

const _hasValidLimits = (extra, answer) => {
  if (!answer || answer.length === 0) {
    return false;
  }

  const value = parseFloat(_getValue(answer));
  const { min, max } = extra;
  if (min && max) {
    return value >= min && value <= max;
  }
  return true;
};

const _runValidations = (mandatory, type, answer, options = [], extra = {}) => {
  const _validateChildren = (childrenOptions, childrenAnswer) =>
    childrenOptions
      .filter(o => Object.keys(o).includes('extra'))
      .reduce((p, o) => {
        const a = childrenAnswer.find(ans => ans.choice === o.key);
        if (!a) {
          return p;
        }
        const [, childrenErrors] = _runValidations(o.extra.mandatory, o.extra.type, [a], []);
        return [...p, ...childrenErrors];
      }, []);

  let errors = [];

  if (mandatory && !answer) {
    errors.push({
      message: VALIDATION_ERRORS.REQUIRED
    });
  } else if (mandatory && answer) {
    switch (type) {
      case 'text':
        if (!_hasValidText(answer) && mandatory) {
          errors.push({
            message: VALIDATION_ERRORS.REQUIRED
          });
        }
        break;
      case 'date':
        if (!_hasValidDate(answer) && mandatory) {
          errors.push({
            message: VALIDATION_ERRORS.INVALID_DATE
          });
        }
        break;

      case 'number':
      case 'numeric':
        if (!_hasValidNumber(answer) && mandatory) {
          errors.push({
            message: VALIDATION_ERRORS.INVALID_NUMBER
          });
        } else if (!_hasValidLimits(extra, answer)) {
          errors.push({
            message: VALIDATION_ERRORS.INVALID_LIMIT
          });
        }
        break;

      case 'single_choice':
        if (!_hasValidOption(options, answer) || !_hasSingleOption(answer)) {
          errors.push({
            message: VALIDATION_ERRORS.INVALID_CHOICE
          });
        } else {
          errors = [...errors, ..._validateChildren(options, answer)];
        }
        break;
      case 'multiple_choice':
        if (!_hasValidOption(options, answer) || !_hasMultipleOptions(answer)) {
          errors.push({
            message: VALIDATION_ERRORS.INVALID_CHOICE
          });
        } else {
          errors = [...errors, ..._validateChildren(options, answer)];
        }
        break;
      default:
        break;
    }
  }
  return [errors.length === 0, errors];
};

export const validateQuestion = (question, questionAnswers) =>
  _runValidations(
    question.mandatory,
    question.answer.type,
    questionAnswers,
    question.answer.options,
    question.answer.extra
  );

export const questionsValidator = (questions, answers) => {
  const validationErrors = questions.reduce((prev, question) => {
    const answer = answers[question.key];
    const [isValid, errors] = validateQuestion(question, answer);
    if (!isValid) {
      prev[question.key] = errors;
    }

    return prev;
  }, {});

  return [Object.keys(validationErrors).length === 0, validationErrors];
};
