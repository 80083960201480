import { combineReducers } from 'redux';
import PatientClinicalOutcomesStatisticsReducer from '../views/CoaStatistics/slice/reducer';
import PatientDimensionsReducer from '../views/ScoreDimensions/slice/reducer';
import PatientScoresWithAverageReducer from '../views/ScoreWithAverage/slice/reducer';
import PatientClinicalOutcomesReducer from '../views/slice/reducer';

const PatientCoaMeaningReducer = combineReducers({
  coas: PatientClinicalOutcomesReducer,
  coaScores: PatientScoresWithAverageReducer,
  coasStatistics: PatientClinicalOutcomesStatisticsReducer,
  coasDimensions: PatientDimensionsReducer
});

export default PatientCoaMeaningReducer;
