import { ECG_AFIB, ECG_CATEGORY, ECG_CHART } from 'constants/measurements';
import { groupBy } from 'lodash';
import { getRandomNumber } from 'utils/app';
import { moment } from 'utils/dates';
import { makeTrans } from 'utils/translations';

export const categoryMaps = {
  '8867-4': 'blood-pressure', // heart rate
  '15074-8': 'glucose',
  '3141-9': 'weight',
  '55284-4': 'blood-pressure',
  'LP14635-4': 'glucose',
  steps: 'steps'
};

export class GlucoseClassifier {
  constructor() {
    this.icons = {
      'glucose-fasting': {
        icon: 'icon-no-apple',
        label: makeTrans('Fasting')
      },
      'glucose-pre-meal': {
        icon: 'icon-apple',
        label: makeTrans('Before meal')
      },
      'glucose-pos-meal': {
        icon: 'icon-half-apple',
        label: makeTrans('Post meal')
      }
    };
  }
}

export const getIcon = code => {
  switch (code) {
    case '15074-8':
    case 'glucose-pre-meal':
      return {
        class: 'icon-apple',
        label: makeTrans('Before meal')
      };
    case 'glucose-pos-meal':
      return {
        class: 'icon-half-apple',
        label: makeTrans('Post meal')
      };
    case 'glucose-fasting':
      return {
        class: 'icon-no-apple',
        label: makeTrans('Fasting')
      };
    default:
      return undefined;
  }
};

const getFiltersBySlug = slug => {
  let filters = [];

  switch (slug) {
    case 'heart-rate': {
      filters = [
        {
          slug: 'weekly',
          title: makeTrans('Weekly'),
          ndays: 7,
          range: 7,
          aggBy: 'value',
          aggFuncs: 'min,max',
          aggValues: 'code_id,device_id,measured_at__date,code__unit'
        },
        {
          slug: 'monthly',
          title: makeTrans('Monthly'),
          ndays: 30,
          range: 30,
          aggBy: 'value',
          aggFuncs: 'min,max',
          aggValues: 'code_id,device_id,measured_at__date,code__unit'
        }
      ];
      break;
    }
    default: {
      filters = [
        // { slug: 'daily', title: makeTrans('Daily'), ndays: 1, range: 0 },
        { slug: 'weekly', title: makeTrans('Weekly'), ndays: 7, range: 7 },
        { slug: 'monthly', title: makeTrans('Monthly'), ndays: 30, range: 30 }
        // { slug: 'yearly', title: makeTrans('Yearly'), ndays: 365 },
      ];
      break;
    }
  }

  return filters;
};

export class ChartFilters {
  constructor(slug = '') {
    this.filters = getFiltersBySlug(slug);
  }

  getQueryParam = (slug, category = '') => {
    const filter = this.filters.find(fi => fi.slug === slug);
    let params = ``;

    switch (category) {
      case 'heart-rate': {
        params = `last_number_days=${filter.ndays}&agg_by=${filter.aggBy}&agg_funcs=${filter.aggFuncs}&agg_values=${filter.aggValues}`;
        break;
      }
      default: {
        params = `ts=${filter.slug}&last_number_days=${filter.ndays}`;
        break;
      }
    }

    return params;
  };

  /**
   * Create min and max date intervale. This method is used for charts
   * Whe use current date to get previous data ex: last 7 days, last 30 days
   *
   * @param {string} slug selected filter ex: Daily, Weekly, Monthly, Yearly
   *
   * @returns {object} {min: date, max: date}
   */
  getAxisRange = slug => {
    const filter = this.filters.find(fi => fi.slug === slug);

    const range = {
      min: new Date(moment().subtract(filter.ndays, 'days').toDate()).getTime(),
      max: new Date(moment().toDate()).getTime()
    };
    return range;
  };
}

export class Scales {
  constructor(code, scales = {}) {
    this.code = code;
    this.scales = scales[code] ? scales[code].scales : {};
  }

  getScale(values = []) {
    const scaleKey = this.getScaleKey(values);
    if (scaleKey) {
      const scale = this.scales[scaleKey];
      return { text: scale.text, color: scale.color };
    }
  }

  getScaleKey(values = []) {
    return Object.keys(this.scales).find(s => {
      const { criteria } = this.scales[s];
      const criteriaFunc = window.Function('values', `return ${criteria}`);
      return criteriaFunc(values);
    });
  }
}

class FormDataBloodPressure {
  constructor(code, unit, name) {
    this.code = code;
    this.name = name;
    this.fields = [
      {
        name: makeTrans('Systolic blood pressure'),
        code: '8480-6',
        widget: 'text',
        unit
      },
      {
        name: makeTrans('Diastolic blood pressure'),
        code: '8462-4',
        widget: 'text',
        unit
      }
    ];
  }
}

class FormDataGlucose {
  constructor(code, unit, name) {
    this.code = code;
    this.name = name;
    this.fields = [
      {
        name,
        code,
        widget: 'text',
        unit
      }
    ];
    this.classifier = new GlucoseClassifier();
  }
}

class FormData {
  constructor(code, unit, name) {
    this.code = code;
    this.name = name;
    this.fields = [
      {
        name,
        code,
        widget: 'text',
        unit
      }
    ];
  }
}

export const formDataFactory = data => {
  switch (data.category) {
    case '55284-4':
      return new FormDataBloodPressure(data.category, data.unit, data.name);
    case '15074-8':
      return new FormDataGlucose(data.category, data.unit, data.name);
    default:
      return new FormData(data.category, data.unit, data.name);
  }
};

const getClassifier = category => {
  if (category === '15074-8') {
    return new GlucoseClassifier();
  }
  return undefined;
};

const _format = (string, args) => {
  let s = string;

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const k in args) {
    s = s.replace(`{${k}}`, args[k]);
  }

  return s;
};

const buildDisplay = (values = [], display) => (values.length > 0 ? _format(display, values) : undefined);

class Measurement {
  // list of editable measurements
  getEditable(dash = false) {
    const editables = ['3137-7', '3141-9', '15074-8', '8480-6', '8462-4', '55284-4', '8867-4'];
    if (dash) {
      editables.push('55284-4');
    }
    return editables;
  }

  constructor(data, dash = false) {
    this.id = data.id && data.id !== '' ? data.id : `${getRandomNumber()}`;
    this.category = data.category;
    this.url = data.category === ECG_CATEGORY ? data.extra?.download_url : data.url;
    this.slug = categoryMaps[data.category];
    this.title = data.title;
    this.subtitle = this.getDescription(data.description, data.measured_at);
    this.display = data.display;
    this.reading = buildDisplay(data.values, data.display);
    this.unit = data.unit;
    this.values = data.values;
    this.color = data.color;
    this.label = data.label;
    this.readOnly = !this.getEditable(dash).includes(data.category);
    this.date = data.measured_at;
    this.editableForm = formDataFactory(data);
    this.icon = getIcon(data.code);
    this.classifier = getClassifier(data.category);
    this.code = data.code || data.category;
  }

  getDescription = (description, date) => {
    if (!description && date) {
      return moment(date).local().format('L | HH:mm');
    }
    return description;
  };
}

export class MeasurementQuestionAnswer {
  constructor(data, unit, display = '{0}') {
    const { answers, question } = data;
    this.title = question.title;
    this.values = this._getAnswers(answers);
    this.display = display;
    this.unit = unit;
    this.reading = buildDisplay(this.values, this.display);
    this.readOnly = true;
  }

  _getAnswers = answers => answers.map(a => a.value);
}

export class DashWeightMeasurement extends Measurement {
  constructor(data) {
    super(data);

    const weight = data.values.length > 0 ? data.values[0] : 0;
    const height = data.extra && data.extra.values.length > 0 ? data.extra.values[0] : 0;
    const bmi = weight / (height / 100) ** 2;
    const scaleObj = new Scales('39156-5', data.scales).getScale([bmi]);

    if (bmi > 0 && Number.isFinite(bmi) && !Number.isNaN(bmi)) {
      this.scale = {
        ...scaleObj,
        text: `BMI: ${bmi.toFixed(0)} ${scaleObj ? `- ${scaleObj.text}` : ''}`
      };
    }
  }
}

export class DonutChart {
  constructor(data) {
    const group = groupBy(data, 'severity_uid');

    this.series = data.length > 0 ? Object.keys(group).map(level => group[level].length) : [];
    this.colors = Object.keys(group).map(level => group[level][0].color);
    this.scale = Object.keys(group).map(level => ({
      text: group[level][0].severity_label,
      color: group[level][0].color,
      id: level
    }));
    this.type = 'donut';
  }
}

export class DesiredWeight {
  constructor(measurement) {
    const heightPow = (measurement.values[0] / 100) ** 2;

    this.min = 18 * heightPow;
    this.max = 25 * heightPow;
  }
}

export const measurementDashFactory = data => {
  switch (data.category) {
    case '3141-9':
      return new DashWeightMeasurement(data, true);
    default:
      return new Measurement(data, true);
  }
};

export default Measurement;

export const normalizeScales = scales =>
  scales.reduce(
    (p, category) => ({
      ...p,
      [category.code]: {
        ...category,
        scales: category.scales.reduce(
          (ps, s) => ({
            ...ps,
            [s.key]: {
              ...s
            }
          }),
          {}
        )
      }
    }),
    {}
  );

export class MeasurementTimeline {
  constructor(data) {
    this.id = data.id;
    this.category = data.code;
    this.code = data.code;
    this.device = data.device;
    this.unit = data.unit;
    this.value = data.value.toFixed(0);
    this.name = data.name;
    this.date = data.measured_at;
    this.color = data.color;
    this.icon = getIcon(data.code);
    this.classifier = getClassifier(data.code);
  }
}

export class ECGReading {
  ecgValues = {
    '-1': makeTrans('Inconclusive'),
    0: makeTrans('Good'),
    1: makeTrans('Warning')
  };

  constructor(reading) {
    this.id = reading[ECG_AFIB]?.uid;
    this.date = reading[ECG_AFIB]?.measured_at;
    this.reading = this.ecgValues[reading[ECG_AFIB]?.value] || '';
    this.description = reading[ECG_AFIB]?.severity_label;
    this.color = reading[ECG_AFIB]?.color;
    this.url = reading[ECG_CHART]?.svalue;
    this.category = ECG_CATEGORY;
  }
}
