import { ECG_CATEGORY } from 'constants/measurements';
import { chain, get } from 'lodash';
import Measurement, {
  ECGReading,
  getIcon,
  measurementDashFactory,
  MeasurementTimeline,
  normalizeScales
} from 'models/Measurement';
import {
  FETCH_MEASUREMENTS,
  FETCH_MEASUREMENT_DETAIL,
  FETCH_MEASUREMENT_DETAIL_READINGS,
  FETCH_SCALES_MEASUREMENT,
  FETCH_TIMELINE,
  FETCH_TIMELINE_NEXT_PAGE,
  MEASUREMENTS_JWT,
  MEASUREMENT_CODE_CHANGE,
  RESET_MEASUREMENT_DETAIL_READINGS,
  RESET_SELECTED_PATIENT
} from '../constants/actionTypes';

export const initial = {
  dashboard: {
    items: []
  },
  scales: {},
  details: {},
  aggregations: {},
  readings: {},
  auth: {
    JWT: undefined,
    URL: undefined,
    RENEW: undefined
  }
};

export default function (state = initial, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_MEASUREMENTS: {
      let items = [];
      if (payload && Array.isArray(payload)) {
        items = payload;
      }

      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          items: items.map(data => measurementDashFactory({ ...data, scales: state.scales }))
        }
      };
    }

    case MEASUREMENTS_JWT: {
      return {
        ...state,
        auth: {
          JWT: payload.jwt,
          URL: payload.url,
          RENEW: payload.renew
        }
      };
    }

    case FETCH_MEASUREMENT_DETAIL: {
      return {
        ...state,
        details: {
          ...state.details,
          [payload.code]: payload.data
            ? payload.data.map(data => new Measurement({ ...data, scales: state.scales }))
            : []
        }
      };
    }

    case FETCH_SCALES_MEASUREMENT: {
      return {
        ...state,
        scales: normalizeScales(action.payload)
      };
    }

    case FETCH_TIMELINE: {
      const { category, data } = action.payload;
      return {
        ...state,
        timelines: {
          ...state.timelines,
          [category]: {
            links: data.links,
            count: data.count,
            pages: data.num_pages,
            items: data.results.map(m => new MeasurementTimeline(m))
          }
        }
      };
    }

    case FETCH_TIMELINE_NEXT_PAGE: {
      const { category, data } = action.payload;
      return {
        ...state,
        timelines: {
          ...state.timelines,
          [category]: {
            links: data.links,
            count: data.count,
            pages: data.num_pages,
            items: [...state.timelines[category].items, ...data.results.map(m => new MeasurementTimeline(m))]
          }
        }
      };
    }

    case MEASUREMENT_CODE_CHANGE: {
      const { id, code, category, detail } = action.payload;

      return {
        ...state,
        details: Object.keys(state.details).reduce((p, key) => {
          if (detail === key) {
            return {
              ...p,
              [key]: state.details[key].map(m => {
                if (m.id === id) {
                  return {
                    ...m,
                    code,
                    icon: getIcon(code)
                  };
                }
                return m;
              })
            };
          }
          return { ...p, ...state.details[key] };
        }, {}),
        timelines: {
          ...state.timelines,
          [category]: (get(state.timelines[category], 'items', state.timelines[category]) || []).map(m => {
            if (m.id === id) {
              return {
                ...m,
                code,
                icon: getIcon(code)
              };
            }
            return m;
          })
        }
      };
    }

    case FETCH_MEASUREMENT_DETAIL_READINGS: {
      const { category, data } = action.payload;
      return {
        ...state,
        readings: {
          ...state.readings,
          [category]:
            category === ECG_CATEGORY
              ? chain(data.results)
                  .groupBy('uid')
                  .map(item => {
                    const uidObj = {};
                    item.map(entry => {
                      uidObj[entry.code] = entry;
                      return entry;
                    });
                    return new ECGReading(uidObj);
                  })
                  .value()
              : data
        }
      };
    }

    case RESET_MEASUREMENT_DETAIL_READINGS: {
      const { category } = action.payload;
      return {
        ...state,
        readings: {
          ...state.readings,
          [category]: []
        }
      };
    }

    case RESET_SELECTED_PATIENT: {
      return initial;
    }

    default:
      return state;
  }
}
