import {
  CHANGE_ROLE,
  CHANGE_USER_INSTITUTION,
  FETCH_INSTITUTIONS_DISEASES,
  FETCH_PROFESSIONAL_DATA,
  FETCH_PROFESSIONAL_DISEASES,
  FETCH_PROFESSIONAL_INSTITUTIONS
} from 'constants/actionTypes';

const initialState = {
  diseases: [],
  institutions: [],
  selectedInstitution: {},
  lastStatusRequest: undefined,
  selectedRole: undefined
};

export default function (state = initialState, action) {
  let newState;

  switch (action.type) {
    case FETCH_PROFESSIONAL_DATA: {
      const { institutions = [] } = action.payload;

      const institution = [
        ...institutions.map(i => ({
          ...i,
          ...i.institution,
          roles: [i.role]
        }))
      ];

      const payload = {
        ...action.payload,
        institutions: institution
      };
      const professional = {
        ...Object.assign(initialState, payload),
        selectedInstitution: state.selectedInstitution
      };
      return professional;
    }

    case CHANGE_ROLE: {
      const newRole = action.payload;
      newState = {
        ...state
      };

      // check if role exists in selected institution
      if (newState.selectedInstitution && newState.selectedInstitution.roles.includes(newRole)) {
        newState.selectedRole = action.payload;
      }
      return newState;
    }

    case FETCH_PROFESSIONAL_INSTITUTIONS: {
      newState = {
        ...state
      };
      // newState.institutions = [...state.institutions, PrivatePractice];

      [newState.selectedInstitution] = newState.institutions;
      const institutionID = sessionStorage.getItem('institutionID');
      if (institutionID) {
        const matchInstitutions = newState.institutions.find(i => i.id === institutionID);
        if (matchInstitutions) {
          newState.selectedInstitution = matchInstitutions;
        }
      }
      // if current role is not suitable to institution change to the first in roles list
      if (!newState.selectedInstitution.roles.includes(newState.selectedRole)) {
        [newState.selectedRole] = newState.selectedInstitution.roles;
      }
      return newState;
    }

    case CHANGE_USER_INSTITUTION: {
      newState = {
        ...state
      };
      let selectedInstitution = newState.institutions.find(i => i.id === action.payload);
      // select first institution if none
      if (!selectedInstitution && !newState.selectedInstitution) {
        [selectedInstitution] = newState.institutions;
      }

      if (selectedInstitution) {
        newState.selectedInstitution = selectedInstitution;
        // if current role is not suitable to institution change to the first in roles list
        if (!selectedInstitution.roles.includes(newState.selectedRole)) {
          [newState.selectedRole] = selectedInstitution.roles;
        }
      }
      return newState;
    }

    case FETCH_PROFESSIONAL_DISEASES: {
      return {
        ...state,
        diseases: action.payload.map(instDisease => ({
          ...instDisease.disease,
          event: instDisease.event,
          configurations: instDisease.configurations,
          label: instDisease.disease.name,
          value: instDisease.disease.slug
        }))
      };
    }

    case FETCH_INSTITUTIONS_DISEASES: {
      const data = {
        ...state,
        selectedInstitution: {
          ...state.selectedInstitution,
          diseases: action.payload
        }
      };
      return data;
    }
    default:
      return state;
  }
}
